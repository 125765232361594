
/*** MOBILE ***/

/* hide mobile elements on desktop */
.mobile {
  display: none;
}

@media only screen 
  and (max-width: 1000px) {

  .mobile {
    display: inherit;
  }

  .app {
    padding: 0;
  }

  .panel {
    box-shadow: none;
    padding: 1rem;
    width: 100%;
  }

  .cells {
    max-width: 100%;
    width: fit-content;
    overflow-x: auto;
    justify-content: flex-start;
  }
}